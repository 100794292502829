@import "../../style/variables";
@import "../../style/mixins";

.wrapper {
  // position: absolute;
  // top: 2rem; // 32px;
  // left: 1.6875rem; // 27px;
  // z-index: 1000;
  // @include fluid('margin-top', 2rem);

  .dash {
    width: 1rem; // 16px;
    height: 0.125rem; // 2px;
    background-color: #404040;
    margin: 0.25rem 0; // 4px 0;

    &.half {
      width: 0.5rem; // 8px;
    }

    &.white {
      background-color: white;
    }
  }
}