@import "../../style/variables";
@import "../../style/mixins";
@import "../../style/shared";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  background-color: #EFF2F2;
  
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);

  input {
    height: auto;
    @include fluid('font-size', $ys-font-size); // 28px;
    @include fluid(('padding-top', 'padding-bottom'), $ys-padding-y + 0.25rem - 0.0625rem);
    @include fluid(('padding-left', 'padding-right'), $ys-padding-x); // 15px 1rem
    @include fluid('width', 11rem, true);
    @include fluid('margin-right', 0.375rem);
    margin-top: 0;
    border-radius: 0;
    line-height: $ys-line-height;
  }
}

.panelwrapper {
  @include fluid("width", 21.4375rem);
  margin: 0 auto;
}

.title {
  font-weight: $font-weight-medium;
  @include fluid('font-size', 1.75rem);
  line-height: 1.214285714;
  @include fluid('margin-top', 2rem);
  @include fluid('margin-bottom', 0.5rem);
}

.description {
  font-weight: $font-weight-light;
  @include fluid('font-size', 1rem);
  @include fluid('margin-bottom', 2rem);
  line-height: 1.375;
}

.subtitle {
  font-weight: $font-weight-medium;
  @include fluid('font-size', 1.3125rem);
  line-height: 1.142857143;
}

.prompt {
  font-weight: $font-weight-light;
  @include fluid('font-size', 1rem);
  @include fluid(('padding-left', 'padding-right'), 1rem);
}

.go {
  @include fluid('margin-top', 3rem);
}

.tabform {
  @include fluid('margin-top', 1.625rem);
  text-align: left;
  line-height: 1.375;

  &.forgot {
    input {
      @include fluid('width', 15.625rem);
    }
  }
}