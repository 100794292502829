@import "../../style/variables";
@import "../../style/mixins";

// .summary {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: stretch;
//   min-height: 100vh;
//   padding: 1rem;
//   text-align: center;
//   background-color: #eff2f2;

//   img {
//     display: block;
//     margin-left: auto;
//     margin-right: auto;
//   }

//   .panelwrapper {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-grow: 1;
//     margin-top: 1rem;
//     // background-color: blanchedalmond;

    .panel {
      @include fluid("width", 21.4375rem);
      // @include fluid-type('height', $portrait-min-vw, $portrait-max-vw, 25.7125rem, 40.0625rem);
      @include fluid('margin-top', 2.4rem);
      text-align: center;

      background-color: #fff;
      @include fluid('border-radius', 0.625rem);
      @include fluid('padding', 1rem);
      // background-color: lightblue;

      .title-row {
        display: flex;
        align-items: stretch;

        .cv {
          display: flex;
          align-items: center;
        }
      }

      .headerwrapper {
        @include fluid("min-height", 11.5625rem); // 30px, 96px);
        overflow: hidden;
        flex-direction: column;

        .header {
          font-weight: $font-weight-medium;
          @include fluid( "font-size", 1.75rem); // 30px, 96px);
          color: #404040;
          @include fluid('margin-top', 0.75rem); // 36px;
          text-align: center;
          line-height: 1.233333333;
        }

        .details {
          @include fluid('font-size', 1.25rem); // 20px, 64px);
          font-weight: $font-weight-medium;
          color: #404040;
          @include fluid('margin-top', 0.875rem); // 14px;
          text-align: center;
        }
      }

      .landback {
        display: none;
      }

      .back {
        font-weight: $font-weight-medium;
        @include fluid('font-size', 0.875rem); // 14px;
        line-height: 1.5; // 21px;
        @include fluid('margin-top', 0.625rem); // 10px;
        color: #01a4ad;
        text-align: center;
        cursor: pointer;
      }

      .list {
        &.en {
          @include fluid("margin-left", 2.5rem);
        }
        &.es {
          margin-left: 0;
        }

        @include fluid("margin-top", 1rem);
        text-align: left;

        .segment {
          @include fluid("height", 2.625rem);
          @include fluid("font-size", 1rem);

          // font-size: 1.0625rem; // 17px;

          .num {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            @include fluid(("width", "height", "line-height"), 1.626rem);
            border-radius: 100%;
            background: #ec6529;
            font-weight: $font-weight-light;
            color: white;
            @include fluid('margin-right', 0.875rem); // 14px;
          }

          .segname {
            font-weight: $font-weight-light;
            line-height: 1.235294118; // 21px;
            display: inline-block;

            &.hi {
              font-weight: $font-weight-medium;
              color: #ec6529;
            }
          }
        }
      }
    }
//   }
// }

// @media screen and (orientation: landscape) and (max-height: $landscape-max-vh) {
//   .summary {
//     .panelwrapper {
//       .panel {
//         height: 100%;
//         @include fluid-type-land(
//           "width",
//           $landscape-min-vh,
//           $landscape-max-vh,
//           39.6875rem,
//           61.836868008rem
//         );

//       .back {
//         display: none;
//       }

//       .landback {
//         position: absolute;
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-items: center;
//         left: 1rem;
//         bottom: 1rem;
//         height: 2.625rem;
//         width: 2.625rem;
//         color: #aaa;
//         cursor: pointer;
//       }

//       .title-row {
//           display: flex;
//           align-items: center;

//           .headerwrapper {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             .header {
//               margin-top: 0;
//               @include fluid-type-land(
//                 "font-size",
//                 $landscape-min-vh,
//                 $landscape-max-vh,
//                 1.875rem,
//                 1.875rem * $landscape-scale-factor
//               );
//             }

//             .details {
//               @include fluid-type-land(
//                 "font-size",
//                 $landscape-min-vh,
//                 $landscape-max-vh,
//                 1.25rem,
//                 1.25rem * $landscape-scale-factor
//               );
//             }
//           }

//           .list {
//             margin-top: 0;
//             margin-left: 1.5rem;

//             .segment {
//               height: calc((100vh - 5rem - 3.75rem) / 8);

//               .num {
//                 @include fluid-type-land(
//                   "width",
//                   $landscape-min-vh,
//                   $landscape-max-vh,
//                   1.5rem,
//                   1.5rem * $landscape-scale-factor
//                 );
//                 @include fluid-type-land(
//                   "height",
//                   $landscape-min-vh,
//                   $landscape-max-vh,
//                   1.5rem,
//                   1.5rem * $landscape-scale-factor
//                 );
//                 @include fluid-type-land(
//                   "font-size",
//                   $landscape-min-vh,
//                   $landscape-max-vh,
//                   1.0625rem,
//                   1.0625rem * $landscape-scale-factor
//                 );
//               }

//               .segname {
//                 font-size: 1rem;
//               }
//             }
//           }
//         }

//         @media screen and (orientation: landscape) {
//           font-size: 1rem;
//         }
//       }
//     }
//   }
// }
