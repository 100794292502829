@import "../../style/variables";
@import "../../style/mixins";
@import "../../style/shared";

.panel {
  min-height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  @include fluid("width", 21.4375rem);
  @include fluid('margin-top', 2.4rem);
  @include fluid('border-radius', 0.625rem);
  @include fluid('padding', 1rem);
  min-height: 33.625rem;
}

.inner {
  display: flex;
  flex-direction: column;
  text-align: center;
  @include fluid('min-height', 19.1rem);
}

.prompt {
  display: block;
  font-weight: $font-weight-medium;
  @include fluid("font-size", 1.5rem);
  // @include fluid("margin-bottom", 0.75rem);
  line-height: 1.5;
  text-align: center;
  user-select: none;
}