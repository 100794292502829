/**
 * Strips the unit (px, rem, etc.) from a measurement.
 */
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

/**
 * Generates fluid type rules for the supplied property. It is IMPORTANT to keep the units the same
 * for $min-vw, $max-vw, $min-value, $max-value, otherwise the mixin doesn't work.
 */
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value, $important: false) {
  @each $property in $properties {
    @if $important == true {
      #{$property}: $min-value !important;
    }
    @else {
      #{$property}: $min-value;
    }
  }
  @media only screen and (min-width: $min-vw) {
    @each $property in $properties {
      @if $important == true {
        #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})) !important;
      }
      @else {
        #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
    }
  }
  @media only screen and (min-width: $max-vw) {
    @each $property in $properties {
      @if $important == true {
        #{$property}: $max-value !important; 
      }
      @else {
        #{$property}: $max-value; 
      }
    } 
  }
}

@mixin fluid-type-land($properties, $min-vh, $max-vh, $min-value, $max-value) {
  @each $property in $properties { #{$property}: $min-value; }
  @media (min-height: $min-vh) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vh - #{$min-vh}) / #{strip-unit($max-vh - $min-vh)}));
    }
  }
  @media (min-height: $max-vh) { @each $property in $properties { #{$property}: $max-value; } }
}

@mixin fluid($properties, $val, $important: false, $scale: $portrait-scale-factor) {
  @if $val == 0 {
    @each $property in $properties {
      @if $important == true {
        #{$property}: 0 !important;
      }
      @else {
        #{$property}: 0;
      }
    }
  } @else {
    @include fluid-type(
      $properties, $portrait-min-vw, $portrait-max-vw, $val, $val * $scale, $important
    );
  }
}

@mixin fluid-land($properties, $val) {
  @include fluid-type($properties, $landscape-min-vh, $landscape-max-vh, $val, $val * $landscape-scale-factor);
}

@mixin fluid-button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  @include fluid(('padding-top', 'padding-bottom'), $padding-y);
  @include fluid(('padding-left', 'padding-right'), $padding-x);
  @include fluid('font-size', $font-size);
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @if $enable-rounded {
    @include fluid('border-radius', $border-radius);
  } @else {
    border-radius: 0;
  }
}

@mixin fluid-translateX($val) {
  transform: translateX(#{$val});
  @media (min-width: $portrait-min-vw) {
    transform: translateX(calc(#{$val} + #{strip-unit(($val * $portrait-scale-factor) - $val)} * 
        ((100vw - #{$portrait-min-vw}) / #{strip-unit($portrait-max-vw - $portrait-min-vw)})))
  }
  @media (min-width: $portrait-max-vw) {
    transform: translateX(#{$val * $portrait-scale-factor});
  }
}