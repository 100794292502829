@import "../../style/variables";
@import "../../style/mixins";

.sharepanel {

}

.sharepanel {
  position: relative;
  @include fluid("font-size", 0.75rem);
  @include fluid("letter-spacing", 0.0625rem);
  @include fluid("width", 15.625rem);
  @include fluid(("margin-top", "margin-bottom"), 3.5rem);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: $font-weight-medium;
  line-height: 1.333;
  color: #404040;
  @include fluid('min-height', 3rem);

  .shareicon {
    @include fluid("height", 2rem);
    @include fluid(("margin-right", "margin-left"), 1rem);
    float: left;
  }

}

.buttonwrapper, .shareprompt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s;

  &.showing {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.4s;
  }
}

.sharewrapper {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  & + & {
    @include fluid('margin-left', 0.5rem);
  }
}

.sharecount {
  font-weight: $font-weight-light;
  @include fluid('margin-top', 0.25rem);
  @include fluid('font-size', 0.5rem);
}

.sharebutton {
  cursor: pointer;
}

.sharebutton:hover:not(:active) {
  opacity: 0.75;
}

.title {
  font-weight: $font-weight-medium;
  @include fluid('font-size', 1rem);
  text-align: center;
  @include fluid('margin-bottom', 0.5rem);
}