@import "../../style/variables";
@import "../../style/mixins";

.carticon {
  width: 1.6875rem;
  height: 1.6875rem;
}

.logo {
  @include fluid(('width', 'height'), 2.1875rem);  
}

.header {
  @include fluid('padding-top', 1rem);
}

@media print {
  .header {
    display: none;
  }
}