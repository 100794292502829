@import "../../style/variables";
@import "../../style/mixins";
@import "../../style/shared";

// .wrapper {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: stretch;
//   min-height: 100vh;
//   // padding: 0 1rem;
//   text-align: center;
//   background-color: #EFF2F2;

  // img { height: 3.75rem; }

  // .panelwrapper {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex-grow: 1;
  //   margin-top: 1rem;
  //   // background-color: blanchedalmond;
  // }

    .panel {
      @include fluid('width', 21.4375rem);
      @include fluid('height', 25.7125rem);
      @include fluid('margin-top', 3.875rem);
      text-align: center;

      background-color: #fff;
      border-radius: 0.625rem;
      padding: 1rem;
      // background-color: lightblue;  

      .question {
        @include fluid('font-size', 1.75rem); // 28px /  89.6px
        @include fluid('margin-top', 3.3125rem); // 28px /  89.6px
        @include fluid('margin-bottom', 1.5rem); // 28px /  89.6px

        line-height: 1.214285714;
        margin-left: auto;
        margin-right: auto;
      }
  
      input {
        @include fluid('width', 15.625rem);
        @include fluid('height', 3.75rem); // 60px;
        @include fluid('font-size', 1.75rem); // 28px;
        @include fluid(('padding-top', 'padding-bottom'), 0.9375rem);
        @include fluid(('padding-left', 'padding-right'), 1rem); // 15px 1rem
        border-radius: 0;
        margin-left: auto;
        margin-right: auto;
      }
  

      .title {
        @include fluid('font-size', 1.75rem);
        @include fluid('min-height', 1.75rem * 1.2 * 4); 
        @include fluid('margin-top', 1rem);
        font-weight: $font-weight-medium;
        line-height: 1.2;
      }

      .subtitle {
        @include fluid('font-size', 0.75rem);
        @include fluid('line-height', 2rem);
        text-transform: uppercase;
        font-weight: $font-weight-medium;
      }

      .description {
        @include fluid('font-size', 1rem); // 20px, 64px);
        font-weight: $font-weight-medium;
        color: #404040;
        margin-top: 0.875rem; // 14px;
        text-align: center;
      }

      .body {
        font-weight: $font-weight-light;
        @include fluid('font-size', 0.7rem);
        @include fluid('min-height', 0.7rem * 1.5 * 6);
     }

      .ratings {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        min-height: 5.0625rem;
        @include fluid('padding-left', 1rem);
        @include fluid('padding-right', 1rem);

        .rating {
          @include fluid('width', 2.8125rem);
          img {
            @include fluid('width', 2.8125rem);
            @include fluid('height', 2.8125rem);
          }
          .label {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: $font-weight-bold;
            line-height: 1.4;
            @include fluid('margin-top', 0.625rem);
            @include fluid('font-size', 0.625rem);
            @include fluid('letter-spacing', 0.0625rem);
            @include fluid('min-height', 1.75rem);
            text-transform: uppercase;
          }
        }
      }
    }

    .landonly {
      display: block;

      @media screen and (orientation: portrait) { 
        display: none; 
      } 
    }
  // }
// }

// @media screen and (orientation: landscape) and (max-height: $landscape-max-vh) {
//   .wrapper {
//     .panelwrapper {
//       .panel {
//         height: 100%;
//         @include fluid-land('width',  39.6875rem);
//         background-color: lightgreen;

//         // Name input page

//         .question {
//           @include fluid-land('font-size', 1.75rem); // 28px /  89.6px
//           margin: 0 auto 1.5rem; // 69px 33px;
//         }

//         input {
//           height: 3.75rem; // 60px;
//           font-size: 1.75rem; // 28px;
//           padding: 0.9375rem 1rem; // 15px 1rem  

//           height: 3.125rem; // 60px;
//           font-size: 1.75rem; // 28px;
//           padding: 0.9375rem 1rem; // 15px 1rem  
//         }
  
//         .title {
//           @include fluid-land('font-size', 1.75rem);
//           @include fluid-land('min-height', 1.75rem * 1.2 * 2);
//           margin-top: 0;  
//         }

//         .subtitle {
//           @include fluid-land('font-size', 0.75rem);
//           @include fluid-land('line-height', 2rem);
//         }

//         .body {
//           @include fluid-land('min-height', 0.7rem * 1.5 * 3);
//         }

//         .titlerow {
//           display: flex;
//           align-items: stretch;
      
//           .cv { display: flex; align-items: center; }
//         }
      
//         .ratings {
//           min-height: 2.5rem + 0.625rem;
//           max-width: 25rem;
//           margin: 0 auto;
//           .rating {
//             img {
//               @include fluid-land('width', 2.5rem);
//               @include fluid-land('height', 2.5rem);
//             }
//             .label {
//               @include fluid-land('font-size', 0.625rem);
//               margin-top: 0rem;
//             }
//           }
//         }
//       }
//     }
//   }
// }