@import "./variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "./mixins";

.app-view {
  position: relative;
  min-height: 100vh;
  // padding: 0.875rem; // 14px; -- original design spec
  padding: 1rem;
}

.question-panel {
  // height: 26.9375rem; // 431px;
  padding: 1rem;
  // margin-left: 0.5rem; // 8px;
  // margin-right: 0.5rem; // 8px;
  background-color: #fff;
  border-radius: 0.625rem; // 10px;
  // text-align: center;
  justify-content: center;
}

%ygy-button {
  text-transform: uppercase;
  background-color: #EC6529;
  color: #fff;
  border-radius: 0;
  font-size: 0.8125rem;
  margin-top: 1.375rem;
  font-weight: $font-weight-medium;
  border: none;
}

.lg-button {
  @extend %ygy-button;
  width: 15.625rem;
  height: 3.75rem;
}

.sm-button {
  @extend %ygy-button;
  height: 3.125rem;
}

.separator {
  width: 2.2rem;
  height: 0.1875rem;
  background-color: #fff;
  margin: 1.5rem auto;

  &.dark {
    background-color: #999999;
  }

  &.left {
    margin: 1.5rem 0;
  }
}

.carticon {
  position: absolute;
  top: 2rem; // 32px;
  right: 1.6875rem; // 27px;
  z-index: 1000;
  width: 1.6875rem;
  height: 1.6875rem;
}


.btn-ypri {
  @include button-variant(
    $yp-primary-bg, $yp-primary-border,
    $yp-primary-active-bg, $yp-primary-active-border, 
    $yp-primary-active-bg, $yp-primary-active-border
  );
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  // @include fluid('margin-top', 1.375rem);

  &:focus, &.focus {
    // @include box-shadow(none);
    box-shadow: unset;
    border: 0.25rem solid #f8bd39; 
  }

  &:disabled, &.disabled {
    background-color: #919191;
    opacity: 1;
  }
}

.btn-ysec {
  @include button-variant(
    $yp-secondary-bg,        $yp-secondary-border,
    $yp-secondary-active-bg, $yp-secondary-active-border, 
    $yp-secondary-active-bg, $yp-secondary-active-border
  );
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  // @include fluid('margin-top', 1.375rem);

  &:focus, &.focus {
    box-shadow: unset;
    border: 0.25rem solid #d6d8d8; 
  }

  &:disabled, &.disabled {
    background-color: #919191;
    opacity: 1;
  }
}

.btn-yl {
  @include fluid-button-size(
    $yl-padding-y, $yl-padding-x, $yl-font-size, $yl-line-height, $yl-border-radius
  );
}

.btn-ys, .form-control-ys {
  @include fluid-button-size(
    $ys-padding-y, $ys-padding-x, $ys-font-size, $ys-line-height, $ys-border-radius
  );
}

.w250 {
  @include fluid('width', 15.625rem);
}

.wm30 {
  max-width: $btn-max-width;
}

.btn-space {
  @include fluid('margin-top', 1.375rem);
}

.smaller {
  @include fluid('font-size', 0.75rem);
}

@media print {
  .btn-yl, .btn-ys {
    display: none;
  }
}

.nav-tabs, .tab-content {
  // padding-right: ($grid-gutter-width / 2);
  // padding-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);
}

.nav-tabs {
  .nav-link {
    border: none;
    border-radius: 0;
    max-width: 33.333%;
    color: #01A4AD;
    line-height: 1.375;

    &.active {
      color: #404040;
      background-color: #EFF2F2;
    }
  }
}

.form-label {
  font-weight: $font-weight-light;
  @include fluid('font-size', 1rem);
}

.modal-content {
  .modal-header {
    padding-bottom: 0;
  }
  
  .modal-body {
    @include fluid(('padding-left', 'padding-right', 'padding-bottom'), 2rem);

    img {
      margin-left: auto;
      margin-right: auto;
      display: block;
      @include fluid('height', 10rem);
    }
  }
}
