@import "../../style/variables";
@import "../../style/mixins";
@import "../../style/shared";

.cart {
  position: fixed;
  top: 0;
  height: 100vh;
  left: 100%;
  z-index: 20;
  @include fluid('width', $base-panel-width);
  transition: transform 0.4s;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  right: 0;

  &.yammo {
    $min: ($base-panel-width * -1);
    $max: ($base-panel-width * $portrait-scale-factor * -1);

    transition: transform 0.4s;

    transform: translateX($min);
    @media only screen and (min-width: $portrait-min-vw) {
      transform: translateX(calc(#{$min} + #{strip-unit($max - $min)} * ((100vw - #{$portrait-min-vw}) / #{strip-unit($portrait-max-vw - $portrait-min-vw)})))
    }
    @media only screen and (min-width: $portrait-max-vw) {
      transform: translateX($base-panel-width * $portrait-scale-factor * -1);
    }
  }

  &.es {
    .header {
      @include fluid('font-size', 1.2rem);
    }
    .prompt {
      @include fluid('font-size', 0.75rem);
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  @include fluid('height', 5.75rem);
  @include fluid('font-size', 1.3125rem);
  line-height: 1.619047619;
  text-align: center;
  vertical-align: middle;
  background-color: #EDEDED;
}

.list {
  flex-grow: 1;
  overflow-y: scroll;
  background-color: #fff;
  @include fluid('padding', 1rem);

  .item {
    display: block;
    position: relative;
    @include fluid('min-height', 7.6rem);
    @include fluid('padding-top', 0.3rem);

    .imagewrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      @include fluid(('width', 'height'), 5.25rem);
      float: left;

      img {
        @include fluid('max-width', 5.25rem);
        @include fluid('max-height', 5.25rem);
        // @include fluid('left', 1.6875rem);
      }
    }
  
    .title {
      font-weight: $font-weight-medium;
      @include fluid('font-size', 0.875rem);
      line-height: 1.285714286;
      @include fluid('margin-left', 6rem);
    }

    .price {
      font-weight: $font-weight-medium;
      @include fluid('font-size', 0.8rem);
      line-height: 1.2;
      @include fluid('margin-left', 6rem);
      @include fluid('margin-top', 0.75rem);

      span {
        @include fluid('font-size', 0.5625rem);
        font-weight: $font-weight-light;
        line-height: 1.2;
      }

      .sku {
        @include fluid('margin-top', 0.5rem);
        font-weight: $font-weight-light;
      }
    }


    // .sku {
    //   @include fluid('margin-left', 6rem);
    //   @include fluid('margin-top', 0.5rem);
    // }

    .remove {
      font-weight: $font-weight-light;
      @include fluid('margin-left', 6rem);
      @include fluid('font-size', 0.45rem);
      line-height: 2;
      cursor: pointer;
    }
  }
}

.cartfoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #EDEDED;
  @include fluid('padding-top', 1rem);
  @include fluid('padding-bottom', 1.5rem);
  @include fluid('min-height', 13.4375rem);
}

.prompt {
  font-weight: $font-weight-normal;
  @include fluid('font-size', 0.875rem);
  line-height: 1.5;
}

.back {
  font-weight: $font-weight-medium;
  @include fluid('font-size', 0.875rem); // 14px;
  line-height: 1.5; // 21px;
  @include fluid('margin-top', 1.0625rem); // 33px;
  color: #01a4ad;
  cursor: pointer;
}
