@import "../../style/variables";
@import "../../style/mixins";
@import "../../style/shared";

.footer {
  background-color: #707070;
  color: #fff;
  text-align: center;
  @include fluid(("padding-top", "padding-bottom"), 1.5rem);
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);

  .sectiontitle {
    font-weight: $font-weight-bold;
    @include fluid('font-size', 1.125rem);
    @include fluid('margin-top', 1.5rem);
    text-transform: uppercase;
  }

  .link {
    @include fluid('font-size', 1rem);
    @include fluid('margin-bottom', 1.75rem);
  }

  .small {
    @include fluid('font-size', 0.8125rem);
    @include fluid(('padding-left', 'padding-right'), 2rem);
    @include fluid('margin-bottom', 1rem);
  }

  .separator {
    margin: 0.8rem auto;
  }

  a { color: #fff; }
}

@media print {
  .footer {
    page-break-inside: avoid;
  }
}