@import "../../../style/variables";
@import "../../../style/mixins";

.popover.bs-popover-bottom {
  .popover-body {
    padding-bottom: 0.5rem;
    
    .row {
      display: table-row;
      
      .question, .answer {
        @include fluid('padding-bottom', 0.35rem);
        display: table-cell;
        vertical-align: middle;
        line-height: 1.2;
      }
      .question {
        padding-right: 0.5rem;
      }
    }
  }
}
