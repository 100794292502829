@import "../../style/variables";
@import "../../style/mixins";

// .wrapper {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: stretch;
//   min-height: 100vh;
//   padding: 1rem;
//   text-align: center;
//   background-color: #eff2f2;

//   img {
//     height: 3.75rem;
//   }

//   .panelwrapper {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-grow: 1;
//     margin-top: 1rem;
//     // background-color: blanchedalmond;

    .panel {
      @include fluid("width", 21.4375rem);
      @include fluid("height", 25.7125rem);
      @include fluid('margin-top', 3.875rem);
      text-align: center;

      background-color: #fff;
      @include fluid('border-radius', 0.625rem);
      @include fluid('padding', 1rem);

      .question {
        @include fluid("font-size", 1.75rem); // 28px /  89.6px
        @include fluid("margin-top", 4.3125rem); // 28px /  89.6px
        @include fluid("margin-bottom", 3.5rem); // 28px /  89.6px
        line-height: 1.214285714;
        margin-left: auto;
        margin-right: auto;
      }

      input {
        @include fluid('width', 15.625rem);
        @include fluid('height', 3.75rem); // 60px;
        @include fluid('font-size', 1.75rem); // 28px;
        @include fluid(('padding-top', 'padding-bottom'), 0.9375rem);
        @include fluid(('padding-left', 'padding-right'), 1rem); // 15px 1rem
        border-radius: 0;
        margin-left: auto;
        margin-right: auto;
      }

      .landback {
        display: none;
      }

      .title {
        display: flex;
        align-items: center;
        @include fluid("font-size", 1.75rem);
        @include fluid("min-height", 1.75rem * 1.2 * 4);
        // @include fluid("margin-top", 1rem);
        font-weight: $font-weight-medium;
        line-height: 1.2;
      }

      .multititle {
        @include fluid("font-size", 1.5rem);
        font-weight: $font-weight-medium;
        line-height: 1.2;
      }

      .multisubtitle {
        @include fluid("font-size", 0.75rem);
        @include fluid("line-height", 2rem);
        text-transform: uppercase;
        font-weight: $font-weight-medium;
      }

      .subtitle {
        @include fluid("font-size", 0.75rem);
        @include fluid("line-height", 2rem);
        text-transform: uppercase;
        font-weight: $font-weight-medium;
      }

      .body {
        font-weight: $font-weight-light;
        @include fluid("font-size", 0.7rem);
        @include fluid("min-height", 0.7rem * 1.5 * 6);

        .tipdyk {
          display: inline-block;
          font-weight: $font-weight-medium;
        }      
      }

      .options {
        display: grid;
        grid-template-columns: repeat(auto-fit, 7.3125rem); // 117px);
        grid-gap: 1.375rem; // 22px;
        justify-content: space-between;
        align-content: center;
        width: 16rem; // 256px;
        height: 16rem; // 256px;
        // margin: 1.5625rem auto 0; // 25px auto 0; --original spec
        margin: 0.75rem auto 0;

        .square {
          display: inline-block;
          width: 7.3125rem; // 117px;
          min-width: 7.3125rem; // 117px;
          height: 7.3125rem; // 117px;
          min-height: 7.3125rem; // 117px;
          border-radius: 0.3125rem; // 5px;
          border: 0.0625rem solid #cccccc; // 1px
          text-align: center;
          font-weight: $font-weight-bold;
          font-size: 0.625rem; // 10px;
          text-transform: uppercase;

          .pic {
            // height: 3.375rem; // 54px; -- original spec (too large for 3 lines of text)
            height: 2.8rem;
            margin-top: 0.8125rem; // 13px;
          }

          .label {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: $font-weight-bold;
            font-size: 0.625rem; // 10px;
            line-height: 1.4; // 14px;
            letter-spacing: 0.0625rem; // 1px;
            text-transform: uppercase;
            margin: 0.5rem 0.3rem 0; // 11px 7px 0;
            // margin: 0.6875rem 0.4375rem 0; // 11px 7px 0; -- original spec
            color: #707070;

            // min-height: 1.8125rem; // 29px; -- original spec
            min-height: 2.625rem;
          }

          &.selected {
            background-color: #ec6529;
            border: none;

            .label {
              color: white;
            }
          }
        }
      }

      .ratings {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        @include fluid('min-height', 5.0625rem);
        @include fluid("padding-left", 1rem);
        @include fluid("padding-right", 1rem);

        .rating {
          @include fluid("width", 2.8125rem);
          img {
            @include fluid("width", 2.8125rem);
            @include fluid("height", 2.8125rem);
          }
          .label {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: $font-weight-bold;
            line-height: 1.4;
            @include fluid("margin-top", 0.625rem);
            @include fluid("font-size", 0.625rem);
            @include fluid("letter-spacing", 0.0625rem);
            @include fluid("min-height", 1.75rem);
            text-transform: uppercase;
          }
        }
      }
    }

    .disclaim {
      font-weight: $font-weight-light;
      @include fluid('font-size', 0.5rem);
      @include fluid("width", 21.4375rem);
      @include fluid(("padding-left", "padding-right"), 0.5rem);
      text-align: center;
    }

    .landonly {
      display: block;

      @media screen and (orientation: portrait) {
        display: none;
      }
    }
  // }

  
  .back {
    display: table;
    font-weight: $font-weight-medium;
    @include fluid('font-size', 0.875rem); // 14px;
    line-height: 1.5; // 21px;
    @include fluid('margin-top', 1.5rem); // 33px;
    color: #01a4ad;
    cursor: pointer;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .button {
      @include fluid('width', 10rem); // 160px;
      @include fluid('height', 3.125rem); // 50px;
      text-transform: uppercase;
      border-radius: 0;
      @include fluid('font-size', 0.8125rem); // 13px;
      font-weight: $font-weight-medium;
      @include fluid('margin-top', 0.875rem); // 14px;
      border: none;
  
      &.next {
        background-color: #ec6529;
        color: #fff;
        @include fluid('margin-left', 0.75rem); // 12px;
      }
  
      &.prev {
        background-color: #fff;
        @include fluid('border-width', 0.0625rem);
        border-style: solid;
        border-color: #ec6529; // 1px
        color: #ec6529;
      }
    }  
  }
// }


// @media screen and (orientation: landscape) and (max-height: $landscape-max-vh) {
//   .wrapper {
//     .panelwrapper {
//       .panel {
//         position: relative;
//         height: 100%;
//         @include fluid-type-land(
//           "width",
//           $landscape-min-vh,
//           $landscape-max-vh,
//           39.6875rem,
//           61.836868008rem
//         );
//         background-color: lightgreen;

//         // Name input page

//         .question {
//           @include fluid-type-land(
//             "font-size",
//             $landscape-min-vh,
//             $landscape-max-vh,
//             1.75rem,
//             2.726665046rem
//           ); // 28px /  89.6px
//           margin: 0 auto 1.5rem; // 69px 33px;
//         }

//         input {
//           height: 3.75rem; // 60px;
//           font-size: 1.75rem; // 28px;
//           padding: 0.9375rem 1rem; // 15px 1rem

//           height: 3.125rem; // 60px;
//           font-size: 1.75rem; // 28px;
//           padding: 0.9375rem 1rem; // 15px 1rem
//         }

//         .landback {
//           position: absolute;
//           display: flex;
//           flex-direction: column;
//           align-items: center;
//           justify-items: center;
//           left: 1rem;
//           bottom: 1rem;
//           height: 2.625rem;
//           width: 2.625rem;
//           color: #aaa;
//           cursor: pointer;
//         }

//         .title {
//           @include fluid-type-land(
//             "font-size",
//             41.6875rem,
//             75rem,
//             1.75rem,
//             2.726665046rem
//           );
//           @include fluid-type-land(
//             "min-height",
//             $portrait-min-vw,
//             $portrait-max-vw,
//             1.75rem * 1.2 * 2,
//             1.75rem * 1.2 * 2 * $landscape-scale-factor
//           );
//           margin-top: 0;
//         }

//         .subtitle {
//           @include fluid-type-land(
//             "font-size",
//             $landscape-min-vh,
//             $landscape-max-vh,
//             0.75rem,
//             0.75rem * $landscape-scale-factor
//           );
//           @include fluid-type-land(
//             "line-height",
//             $landscape-min-vh,
//             $landscape-max-vh,
//             2rem,
//             2rem * $landscape-scale-factor
//           );
//           // @include fluid-type-land('min-height', $landscape-min-vh, $landscape-max-vh,
//           // 1rem,
//           // 1rem * $landscape-scale-factor
//           // );
//         }

//         .multititle {
//           @include fluid-type-land(
//             "font-size",
//             $landscape-min-vh,
//             $landscape-max-vh,
//             1.5rem,
//             1.5rem * $landscape-scale-factor
//           );
  
//           font-weight: $font-weight-medium;
//           line-height: 1.2;
//         }
  
//         .multisubtitle {
//           @include fluid-type-land(
//             "font-size",
//             $landscape-min-vh,
//             $landscape-max-vh,
//             0.75rem,
//             0.75rem * $landscape-scale-factor
//           );
//           line-height: 1.5rem;
//           text-transform: uppercase;
//           font-weight: $font-weight-medium;
//         }
  
//         .body {
//           @include fluid-type(
//             "min-height",
//             $portrait-min-vw,
//             $portrait-max-vw,
//             0.7rem * 1.5 * 3,
//             0.7rem * 1.5 * 3 * $landscape-scale-factor
//           );
//         }

//         .titlerow {
//           display: flex;
//           align-items: stretch;

//           .cv {
//             display: flex;
//             align-items: center;
//           }
//         }

//         .options {
//           width: 33.375rem;
//           height: 6.5125rem;
//           margin: 0.25rem auto 0;

//           .square {
//             height: 6.5125rem;
//             min-height: 6.5125rem;

//             .pic {
//               height: 2rem;
//             }
//           }
//         }

//         .ratings {
//           min-height: 2.5rem + 0.625rem;
//           max-width: 25rem;
//           margin: 0 auto;

//           .rating {
//             img {
//               @include fluid-type-land(
//                 "width",
//                 $landscape-min-vh,
//                 $landscape-max-vh,
//                 2.5rem,
//                 2.5rem * $landscape-scale-factor
//               );
//               @include fluid-type-land(
//                 "height",
//                 $landscape-min-vh,
//                 $landscape-max-vh,
//                 2.5rem,
//                 2.5rem * $landscape-scale-factor
//               );
//             }

//             .label {
//               @include fluid-type-land(
//                 "font-size",
//                 $landscape-min-vh,
//                 $landscape-max-vh,
//                 0.625rem,
//                 0.973808945rem
//               );
//               margin-top: 0rem;
//             }
//           }
//         }
//       }
//     }

//     .back {
//       display: none;
//       // margin: 0.5rem 0 0;
//     }

//     .button {
//       height: 2.5rem;
//     }

//     @media screen and (orientation: landscape) {
//       height: 2.5rem;
//     }
//   }
// }
