@import "../../style/variables";
@import "../../style/mixins";
@import "../../style/shared";

.prompt {
  // width: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);

  .content {
    flex: 1 0 auto;
    // min-height: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  .logo {
    @include fluid('height', 7.5rem);
    // margin-top: 7.9375rem; // 127px;
    // @media screen and (orientation: landscape) { margin-top: 0; }
  }

  .title {
    font-weight: $font-weight-medium;
    font-size: 1.75rem; // 28px;
    line-height: 2.125rem; // 34px;
    margin-top: 1.8125rem; // 29px;

    @media screen and (orientation: landscape) { margin-top: 1.25rem; }
  }

  .back {
    font-weight: $font-weight-medium;
    font-size: 0.875rem; // 14px;
    line-height: 1.5; // 21px;
    margin: 1.4375rem auto 0; // 23px;
    // margin-top: 1.4375rem; // 23px; -- original spec
    cursor: pointer;
  }


  &.joints {
    background-color: #EF8137;
  }

  &.energy {
    background-color: #01A4AD;
  }

  &.heart {
    background-color: #C0D546;
  }

  &.immune {
    background-color: #EB552A;
  }

  &.aging {
    background-color: #48B67C;
  }

  &.perf {
    background-color: #3FC2CD;
  }

  &.weight {
    background-color: #E13A85;
  }

  &.more {
    background-color: #F8BD3A;
  }
}