@import "../../style/variables";
@import "../../style/mixins";
@import "../../style/shared";

.title-block {
  text-align: center;
  background-color: #EFF2F2;
  @include fluid('min-height', 17rem);
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);

  .titlewrapper {
    @include fluid('width', 21.4375rem);
    margin: 0 auto;

    .title {
      @include fluid('margin-top', 1.875rem);
      @include fluid('font-size', 1.75rem);
      font-weight: $font-weight-medium;
      line-height: 1.2;
      color: #404040;
    }
    
    .step {
      @include fluid('font-size', 1rem);
      @include fluid('margin-bottom', 0.625rem);
      font-weight: $font-weight-light;
      line-height: 1.3125;
      color: #404040;
    }  
  }

}

.prod {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);

  .prodwrapper {
    @include fluid("width", 21.4375rem);
    margin: 0 auto;
    @include fluid("margin-top", 1.3125rem);

    .title {
      @include fluid("font-size", 1.25rem);
      font-weight: $font-weight-medium;
      line-height: 1.2;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: center;
    }

    .intro {
      @include fluid("font-size", 0.75rem);
      @include fluid(('padding-left', 'padding-right'), 1rem);
      @include fluid('margin-bottom', 1.5rem);
      font-weight: $font-weight-light;
      line-height: 1.5;
    }
  }
}

.ha {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);

  .hawrapper {
    @include fluid("width", 21.4375rem);
    margin: 0 auto;
    @include fluid("margin-top", 1.3125rem);
  }
}

.browse {
  background-color: #eff2f2;
  text-align: center;
  @include fluid(('padding-top', 'padding-bottom'), 1.3125rem);
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);

  .browsewrapper {
    @include fluid("width", 21.4375rem);
    margin: 0 auto;
    @include fluid("padding", 0.875rem);

    // .browsetitle {
    //   @include fluid("font-size", 1.5rem);
    //   @include fluid("margin-top", 2.625rem);
    //   @include fluid("margin-bottom", 1rem);
    //   font-weight: $font-weight-medium;
    //   line-height: 1.3;
    // }

    // .browsedesc {
    //   @include fluid("font-size", 0.75rem);
    //   font-weight: $font-weight-light;
    //   line-height: 1.5;
    // }

    // .browsebutton {
    //   @include fluid("width", 15.625rem);
    //   background-color: #707070;
    // }

    .share {
      @include fluid("font-size", 0.75rem);
      @include fluid("letter-spacing", 0.0625rem);
      @include fluid("width", 15.625rem);
      @include fluid(("margin-top", "margin-bottom"), 1rem);
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      font-weight: $font-weight-medium;
      line-height: 1.333;
      color: #404040;

      .shareicon {
        @include fluid("height", 2rem);
        @include fluid(("margin-right", "margin-left"), 1rem);
        float: left;
      }
    }
  }
}
