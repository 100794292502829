// Variables that define the amount of fluid type scaling to perform between the minimum and maximum viewports

$portrait-min-vw: 23.4375rem; // 375px
$portrait-max-vw: 68.75rem; // 1200px

// $portrait-scale-factor: 1.558094312;
$portrait-scale-factor: $portrait-max-vw / $portrait-min-vw / 2;

$landscape-min-vh: 25rem;
$landscape-max-vh: 41.6875rem;

// $landscape-scale-factor: 1.6675;
$landscape-scale-factor: $landscape-max-vh / $landscape-min-vh / 2;


$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semibold:        600;
$font-weight-bold:            700;
$font-weight-black:           900;

$font-family-sans-serif:      "Montserrat", sans-serif;

// $modal-content-bg:                  #333333;
$modal-header-border-width:         0; 
$modal-footer-border-width:         0; 

$theme-colors: (
  "primary": #ec6529,
  "danger": #ff4136,
);

// Button styles

$btn-focus-width: 0.25rem;
$btn-border-width: 0.25rem !default;

$yp-primary-bg: #ec6529;
$yp-primary-border: transparent;
$yp-primary-active-bg: #da571d;
$yp-primary-active-border: transparent;

$yp-secondary-bg: #575757;
$yp-secondary-border: transparent;
$yp-secondary-active-bg: #404040;
$yp-secondary-active-border: transparent;

$yl-padding-y: 1.078125rem;
$yl-padding-x: 0.5rem;
$yl-font-size: 1.0625rem;
$yl-line-height: 1.5;
$yl-border-radius: 0;
$yl-min-width: 15.625rem;

$ys-padding-y: 0.7656rem;
$ys-padding-x: 0.5rem;
$ys-font-size: 1.0625rem;
$ys-line-height: 1.5;
$ys-border-radius: 0;
$ys-min-width: 15.625rem;

$btn-max-width: 30rem;

$base-panel-width: 18.75rem;

/// POPOVER

$popover-font-size:                 0.75rem;
$popover-bg:                        #707070;
$popover-max-width:                 276px !default;
$popover-border-width:              0;
$popover-border-color:              transparent;
$popover-border-radius:             0;
$popover-box-shadow:                0 .25rem .5rem rgba(#000, .2) !default;

$popover-header-bg:                 #707070;
$popover-header-color:              #fff;
$popover-header-padding-y:          .5rem !default;
$popover-header-padding-x:          .75rem !default;

$popover-body-color:                #fff;
$popover-body-padding-y:            0;
$popover-body-padding-x:            $popover-header-padding-x !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;
$popover-arrow-color:               $popover-bg !default;

$popover-arrow-outer-color:         #707070;
