// @import "~bootstrap/scss/functions";
@import "../../style/variables";
@import "../../style/mixins";
@import "../../style/shared";

.small-title {
  font-weight: $font-weight-medium;
  @include fluid('font-size', 1.25rem); // 20px, 64px);
  color: #fff;
  line-height: 1.7;
  margin-top: 5.9375rem; // 95px;
  margin-bottom: 0;
  @media screen and (orientation: landscape) {
    margin-top: 1.5rem;
  }
}

.bigtitle {
  font-weight: $font-weight-bold;
  @include fluid('font-size', 2.75rem); // 60px, 192px);
  @include fluid('margin-bottom', 2rem);
  @include fluid(('padding-left', 'padding-right'), 2rem);
  color: #404040;
  line-height: 1.136363636;
}

.h {
  object-fit: cover;
  object-position: 50% 50%;
  max-width: 100%;
  @include fluid('height', 37.6875rem, false, 1.33);
  }

.subtitle {
  font-weight: $font-weight-bold;
  @include fluid('font-size', 0.875rem); // 14px, 44.8px);
  @include fluid('margin-bottom', 2rem);
  @include fluid(('padding-left', 'padding-right'), 2rem);
  color: #404040;
  line-height: 1.428571429;
  margin-top: 0.625rem; // 10px;
}

.body-text {
  font-weight: $font-weight-light;
  @include fluid('font-size', 0.75rem); // 12px, 38.4px);
  color: #fff;
  line-height: 1.5;
  padding-left: 1.9375rem; // 31px;
  padding-right: 1.9375rem; // 31px;
  margin-top: 0.625rem; // 10px;
}  

.logo {
  @include fluid(('width', 'height'), 2.375rem);
}

.hero {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @include fluid('margin-top', 0.75rem);

  @include fluid('height', 37.6875rem, false, 1.33);

  picture {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    .heroimg {
      object-fit: cover;
      object-position: 50% 50%;
      background-color: green;
    }
  }

  .heroimg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    // @include fluid('height', 37.6875rem, false, 1.33);
  }

  .textwrap {
    // @include fluid('margin-top', 0.625rem);
    // @include fluid('padding-top', 4.25rem);
    z-index: 1;
  }
}

.heart {
  font-weight: $font-weight-medium;
  @include fluid('font-size', 1.25rem);
  line-height: 1.2;
  color: #404040;
  @include fluid(('padding-left', 'padding-right'), 5rem);
  @include fluid('margin-top', 3rem);
  text-align: center;
}

.htitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font-weight-bold;
  @include fluid('font-size', 0.75rem);
  @include fluid('margin-bottom', 1rem);
  line-height: 1.5;
  color: #404040;
  text-align: center;
  @include fluid('min-height', 2.5rem); 

  @include media-breakpoint-down(sm) {
    justify-content: left;
    margin-bottom: 0;
    // @include fluid('margin-bottom', 0.25rem);
  }
}

.hdesc {
  font-weight: $font-weight-light;
  @include fluid('font-size', 0.75rem);
  line-height: 1.5;
  color: #404040;
  @include fluid('margin-bottom', 2rem);
  text-align: center;

  @include media-breakpoint-down(md) {
    text-align: left;
  }
}

.himgwrapper {
  display: block;
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: center;
  }
}

.himg {
  @include fluid(('width', 'height'), 6.875rem);
  @include fluid('margin-bottom', 1.25rem);
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(sm) {
    @include fluid('margin-right', 1.25rem);
    margin-left: 0;
  }
}

.disclaimer {
  font-style: italic;
  @include fluid('font-size', 0.625rem);
  @include fluid('margin-bottom', 1rem);
}