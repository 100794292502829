@import "../../style/variables";
@import "../../style/mixins";

.topictitle {
  @include fluid("font-size", 1.25rem);
  @include fluid("margin-bottom", 0.5rem);
  font-weight: $font-weight-medium;
  line-height: 1.6;
  color: #404040;
}

.challenge {
  @include fluid("font-size", 0.75rem);
  @include fluid("letter-spacing", 0.0625rem);
  @include fluid("margin-bottom", 0.75rem);
  font-weight: $font-weight-light;
  line-height: 1.5;

  .challengetitle {
    @include fluid("letter-spacing", 0.03rem);
    font-weight: $font-weight-medium;
    color: #01a4ad;
  }
}

.products {
  @include fluid("font-size", 0.75rem);
  @include fluid("margin-bottom", 0.75rem);
  font-weight: $font-weight-medium;
  line-height: 1.5;
}

.productwrapper {
  @include fluid("margin-bottom", 2rem);

  img {
    @include fluid("width", 7rem);
    float: left;
  }

  .prodtitle, .price, .sku, .proddesc, .add {
    @include fluid("margin-left", 7.5rem);

    &.link:hover {
      text-decoration: underline;
    }
  }
}

.prodtitle {
  @include fluid("font-size", 0.875rem);
  @include fluid("margin-bottom", 0.5rem);
  font-weight: $font-weight-medium;
  line-height: 1.4;
  color: #01a4ad;
}

.bigprodtitle {
  @include fluid('font-size', 1.75rem);
  @include fluid('margin-bottom', 1rem);
  line-height: 1.214285714;
  font-weight: $font-weight-medium;
  text-align: center;
}

.subtitle {
  @include fluid('font-size', 1rem);
  @include fluid('margin-bottom', 1rem);
  font-weight: $font-weight-medium;
  line-height: 1.25;
  text-align: center;
}

.price {
  font-weight: $font-weight-medium;
  @include fluid('font-size', 0.8rem);
  line-height: 1.2;
  margin: 0;
  @include fluid(('margin-top', 'margin-bottom'), 0.75rem);

  span {
    @include fluid('font-size', 0.5625rem);
    font-weight: $font-weight-light;
    line-height: 1.2;
  }

  .sku {
    @include fluid('margin-top', 0.5rem);
    margin-left: 0;
    font-weight: $font-weight-light;
  }
}

// .price {
//   font-weight: $font-weight-medium;
//   @include fluid('font-size', 0.75rem);
//   line-height: 1.2;
//   margin: 0;
//   @include fluid(('margin-top', 'margin-bottom'), 0.75rem);

//   span {
//     @include fluid('font-size', 0.5625rem);
//     font-weight: $font-weight-light;
//     line-height: 1.2;
//   }
// }

// .sku {
//   @include fluid("font-size", 0.75rem);
//   @include fluid("margin-bottom", 0.5rem);
//   font-weight: $font-weight-light;
//   line-height: 1.5;
// }

.proddesc {
  @include fluid("font-size", 0.75rem);
  @include fluid("margin-bottom", 0.5rem);
  font-weight: $font-weight-light;
  line-height: 1.5;
}

.add {
  @include fluid("width", 21.4375rem - 7.5rem);
}

.buttondiv {
  width: 100%;
  display: block;
  text-align: center;
}

.hepanel, .titlewrapper, .prodlist {
  margin: 0;
  padding: 0;
}

@media print {
  .hepanel {
    page-break-inside: avoid;
    margin-left: 2in;
    margin-right: 2in;
    padding-top: 0.5in;

    .titlewrapper {
      margin-top: 0.5in;
    }
  }
}