@import "../../style/variables";
@import "../../style/mixins";
@import "../../style/shared";

.nav.nav-tabs {
  .nav-item.nav-link {
    padding: 0;
    width: 33.3333333%;

    .tab-title {
      position: relative;
      display: flex;
      align-items: center;
      @include fluid('border-bottom-width', 0.5rem);
      @include fluid('font-size', 1rem);
      border-bottom-style: solid;
      border-bottom-color: white;
      @include fluid('padding-bottom', 1.625rem);
      @include fluid(('padding-left', 'padding-right'), 0.5rem);
      line-height: 1.2;
      @include fluid('min-height', 5.725rem);

      @include media-breakpoint-up(md) {
        @include fluid(('padding-left', 'padding-right'), 2rem);
      }
      @include media-breakpoint-up(lg) {
        @include fluid(('padding-left', 'padding-right'), 3rem);
      }
    }

    &.active {
      .tab-title {
        border-bottom-color: #01A4AD;

        &:after {
          content:'';
          position: absolute;
          // top: 100%;
          top: calc(100% + 0.5rem);
          left: 50%;
          margin-left: -10px;
          width: 0;
          height: 0;
          border-top: solid 10px #01A4AD;
          border-left: solid 10px transparent;
          border-right: solid 10px transparent;
        }
      }
    }
  }
}

.tab-content {
  background-color: #fff;
  padding: 2.5625rem 1rem;

  .tab-pane.active {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
