@import "../../style/variables";
@import "../../style/mixins";
@import "../../style/shared";

.title-block {
  text-align: center;
  background-color: #3fc2cd;
  @include fluid("padding-bottom", 1rem);
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);

  .titlewrapper {
    @include fluid("width", 21.4375rem);
    margin: 0 auto;

    .title {
      @include fluid("font-size", 1.75rem);
      @include fluid("margin-top", 1.875rem);

      font-weight: $font-weight-medium;
      line-height: 1.2;
      color: #fff;
    }

    .step {
      @include fluid("font-size", 1rem);
      @include fluid("margin-bottom", 1.875rem);

      font-weight: $font-weight-light;
      line-height: 1.3125;
      color: #fff;
    }

    .down {
      color: #fff;
      text-align: center;
    }
  }
}

%linkformat {
  @include fluid("font-size", 0.875rem);
  @include fluid("margin-top", 0.5rem);
  font-weight: $font-weight-medium;
  line-height: 1.3;
  color: #01a4ad;
  cursor: pointer;

  a {
    color: #01a4ad;
  }

  &:hover {
    text-decoration: underline;
  }
}

.link {
  @extend %linkformat;

  &:not(.link) {
    @include fluid("margin-left", 6.625rem);
  }

  &.print {
    text-align: center;
  }
}

.sectiontitle {
  font-weight: $font-weight-bold;
  @include fluid('font-size', 1rem);
  @include fluid('margin-top', 2.5rem);
  @include fluid('margin-bottom', 0.75rem);
  text-align: center;
}

.pagelinks {
  margin: 0;
  padding: 0;
  text-align: left;
  @include fluid('margin-left', 1rem);

  & li {
    @extend %linkformat;
  }
}

.recos {
  background-color: #eff2f2;
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);
  @include fluid("padding", 1rem);

  .recoswrapper {
    @include fluid("width", 21.4375rem);
    margin: 0 auto;
    @include fluid("margin-top", 1.3125rem);

    .picwrapper {
      margin-left: 0;
      margin-right: 0;
      @include fluid("margin-bottom", 1.3125rem);

      .pic {
        float: left;
        @include fluid("width", 6.625rem);
        text-align: center;

        img {
          @include fluid("height", 4.5625rem);
        }
      }
    }

    .starts-here {
      @include fluid("font-size", 0.75rem);
      font-weight: $font-weight-medium;
      line-height: 1.5;
      letter-spacing: 0.0625rem;
      margin: 0;
      @include fluid("margin-left", 6.625rem);
      // margin: 0 0 0 6.625rem;
    }

    .paks {
      @include fluid("font-size", 1.25rem);
      font-weight: $font-weight-medium;
      line-height: 1.2;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: center;
    }

    .description {
      @include fluid("font-size", 0.75rem);
      @include fluid("margin-left", 6.625rem);
      font-weight: $font-weight-light;
      line-height: 1.5;

      ul, li {
        margin: 0;
        padding: 0;
      }

      ul {
        @include fluid('margin-left', 1rem);
        @include fluid('margin-bottom', 1rem);
      }

      .callout {
        font-weight: $font-weight-medium;
        line-height: 1.2;
        @include fluid('margin-bottom', 0.25rem);
      }
    }

    .otherrecos {
      margin: 0;
      padding: 0;
    }
  }
}

.morerecos {
  // @include fluid("padding", 1rem);
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);
  border-right: 1px solid #eff2f2;
  border-left: 1px solid #eff2f2;
  @include fluid(('padding-top', 'padding-bottom'), 1.3125rem);

  .morerecoswrapper {
    @include fluid("width", 21.4375rem);
    margin: 0 auto;
    // @include fluid("margin-top", 1.3125rem);

    .moretitle {
      @include fluid("font-size", 1.5rem);
      @include fluid("margin-top", 0.5rem);
      @include fluid("margin-bottom", 1rem);
      font-weight: $font-weight-medium;
      line-height: 1.2;
      text-align: center;
      color: #404040;
    }

    .lead {
      @include fluid("font-size", 0.75rem);
      @include fluid("margin-bottom", 0.5rem);
      font-weight: $font-weight-light;
      line-height: 1.5;
      color: #404040;
    }
  }
}

.browse {
  background-color: #eff2f2;
  text-align: center;
  @include fluid(('padding-top', 'padding-bottom'), 1.3125rem);
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);

  .browsewrapper {
    @include fluid("width", 21.4375rem);
    margin: 0 auto;
    // @include fluid("margin-top", 1.3125rem);
    @include fluid("padding", 0.875rem);

    .browsetitle {
      @include fluid("font-size", 1.5rem);
      @include fluid(('margin-top', 'margin-bottom'), 1rem);
      font-weight: $font-weight-medium;
      line-height: 1.3;
    }

    .browsedesc {
      @include fluid("font-size", 0.75rem);
      font-weight: $font-weight-light;
      line-height: 1.5;
    }

    .browsebutton {
      @include fluid("width", 15.625rem);
      background-color: #707070;
    }
  }
}


@media print {
  @page {
    size: 8.5in 11in;
    margin: 0in;
  }

  html { margin: 0in; }

  body { margin: 0in; }
  
  .title-block {
    text-align: center;
    background-color: #3fc2cd;

    padding-left: 0.5in;
    padding-right: 0.5in;

    @include fluid("padding-bottom", 1rem);
    @include fluid("padding-top", 1.875rem);
  
    .titlewrapper {
      padding-right: 0.25in;
      padding-left: 0.25in;

      // @include fluid("width", 21.4375rem);
      // margin: 0 auto;
  
      .title {
        @include fluid("font-size", 1.75rem);
        @include fluid("margin-top", 1.875rem);
  
        font-weight: $font-weight-medium;
        line-height: 1.2;
        color: #fff;
      }
  
      .step {
        @include fluid("font-size", 1rem);
        @include fluid("margin-bottom", 1.875rem);
  
        font-weight: $font-weight-light;
        line-height: 1.3125;
        color: #fff;
      }
    }
  }
  
  .recos {
    background-color: #eff2f2;
  
    .recoswrapper {
      position: relative;
      width: 100%;
      padding-right: 0.5in;
      padding-left: 0.5in;
      @include fluid("margin-top", 1.3125rem);
  
      .picwrapper {
        margin-left: 0;
        margin-right: 0;
        min-height: 0.75in;
          
        .pic {
          float: left;
          @include fluid("width", 6.625rem);
          text-align: center;
  
          img {
            @include fluid("height", 4.5625rem);
          }
        }
      }
  
      .starts-here {
        @include fluid("font-size", 0.75rem);
        font-weight: $font-weight-medium;
        line-height: 1.5;
        letter-spacing: 0.0625rem;
        margin: 0;
        @include fluid("margin-left", 6.625rem);
        // margin: 0 0 0 6.625rem;
      }
  
      .link {
        display: none;

        &.printable {
          display: block;
          @include fluid("font-size", 0.875rem);
          @include fluid("margin-left", 6.625rem);
          @include fluid("margin-top", 0.5rem);
          font-weight: $font-weight-medium;
          line-height: 1.3;  
        }
      }
  
      .paks {
        @include fluid("font-size", 1.25rem);
        font-weight: $font-weight-medium;
        line-height: 1.2;
        margin-top: 1rem;
        margin-bottom: 1rem;
        text-align: center;
      }
  
      .description {
        @include fluid("font-size", 0.75rem);
        @include fluid("margin-left", 6.625rem);
        font-weight: $font-weight-light;
        line-height: 1.5;
      }

      .otherrecos {
        page-break-before: always;
        display: grid;
        grid-template-columns: 1fr 1fr; // repeat(auto-fit, 3in); // 117px);
        grid-gap: 0.25in; // 22px;
        padding-top: 0.3in; 
      }
    }
  }

  .morerecos {
    page-break-before: always;
    padding-top: 0.5in;
    padding-bottom: 0.5in;
  
    .morerecoswrapper {
      width: 100%;
      padding: 0.5in;
  
      .moretitle {
        @include fluid("font-size", 1.5rem);
        @include fluid("margin-top", 0.5rem);
        @include fluid("margin-bottom", 1rem);
        font-weight: $font-weight-medium;
        line-height: 1.2;
        text-align: center;
        color: #404040;
      }
  
      .lead {
        @include fluid("font-size", 0.75rem);
        @include fluid("margin-bottom", 0.5rem);
        font-weight: $font-weight-light;
        line-height: 1.5;
        color: #404040;
      }
    }
  }
  
  .browse {
    display: none;
  }  
}