@import "./style/variables";
@import "./style/mixins";
@import "./style/shared";

.app {
  position: relative;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.gray {
  background-color: #EFF2F2;
}

.cart {
  position: fixed;
  display: none;
  top: 0;
  left: 100%;
  height: 100%;
  z-index: 1;
  @include fluid('width', $base-panel-width);

  &.yammo {
    display: flex;
    flex-direction: column;
    @include fluid-translateX(-1.0 * $base-panel-width);
    right: 0;
  }
}

.carticon {
  position: absolute;
  top: 2rem; // 32px;
  right: 1.6875rem; // 27px;
  z-index: 1000;
  width: 1.6875rem;
  height: 1.6875rem;
}

.content {
  position: relative;
  min-height: 100vh;
  transition: transform 0.4s;

  &.yam {
    @include fluid-translateX($base-panel-width);
    transition: transform 0.4s;
  }

  &.returning, &.name, &.runner, &.motive {
    background-color: #EFF2F2;
  }

  &.joints {
    background-color: #EF8137;
  }

  &.energy {
    background-color: #01A4AD;
  }

  &.heart {
    background-color: #C0D546;
  }

  &.immune {
    background-color: #EB552A;
  }

  &.aging {
    background-color: #48B67C;
  }

  &.perf {
    background-color: #3FC2CD;
  }

  &.weight {
    background-color: #E13A85;
  }

  &.more {
    background-color: #F8BD3A;
  }
}

.config {
  @include fluid('width', $base-panel-width);
  @include fluid-translateX(-1 * $base-panel-width);
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  padding-left: 0;
  transition: transform 0.4s;
  z-index: 2;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
  
    img {
      @include fluid(('height', 'width'), 1.875rem);
    }

    > div {
      @include fluid('margin-left', 1rem);
    }
  }

  &.yam {
    transform: translateX(0);
    transition: transform 0.4s;
  }

  .flushleft {
    padding-left: 0 !important;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
  
    img {
      @include fluid(('height', 'width'), 1.875rem);
    }

    > div {
      @include fluid('margin-left', 1rem);
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;;
  bottom: 0;
  background-color: #000;
  opacity: 0;
  min-height: 100vh;
  height: 100vh;
  visibility: hidden;

  z-index: 1;
  color: white;
  transition: opacity 0.4s;

  .close {
    position: absolute;
    @include fluid('top', 2rem); // 32px;
    @include fluid(('width', 'height'), 1.25rem);
    opacity: 0;
    transition: opacity 0.4s ease 0.2s;
  }
   
  &.yam {
    visibility: visible;
    opacity: 0.8;
    transition: opacity 0.4s;

    .close {
      @include fluid('left', (1.5625rem + $base-panel-width));
      opacity: 1;
      right: auto;
      transition: opacity 0.4s ease 0.2s;
    }
  }

  &.yammo {
    visibility: visible;
    opacity: 0.8;
    transition: opacity 0.4s;

    .close {
      left: auto;
      opacity: 1;
      @include fluid('right', (1.5625rem + $base-panel-width));
      transition: opacity 0.4s ease 0.2s;
    }
  }
}
