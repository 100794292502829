@import "../../style/variables";
@import "../../style/mixins";
@import "../../style/shared";

.panel {
  @include fluid('padding', 1rem);
  @include fluid('margin-bottom', 0.875rem);
  @include fluid('border-radius', 0.625rem);
  background-color: #fff;

  &.short {
    cursor: pointer;
  }

  &.pick {
    background-color: #fff;
  }

  .interest-group {
    @include fluid('width', 7.125rem);
    float: right;

    .your-interest {
      @include fluid('font-size', 0.75rem);
      @include fluid('letter-spacing', 0.0625rem);
      @include fluid('margin-bottom', 0.5rem);
      font-weight: $font-weight-medium;
    }

    .gauge {
      width: 100%;
    }

    .level {
      @include fluid('font-size', 1.125rem);
      @include fluid('letter-spacing', 0.0625rem);
      @include fluid('margin-bottom', 0.5rem);
      @include fluid('margin-top', 0.5rem);
      line-height: 1;
      text-align: center;
    }
  }

  .title {
    @include fluid('font-size', 1.25rem);
    font-weight: $font-weight-medium;
    line-height: 1.2;

    &:not(.short) {
      @include fluid('min-height', 3rem);
    }
  }

  .separator {
    @include fluid(('margin-top', 'margin-bottom'), 2.00625rem);
  }

  .expander {
    color: #01a4ad;
  }

  .ourpick {
    @include fluid('height', 2rem);
    @include fluid(('margin-top', 'margin-bottom'), 1.1rem);
    @include fluid('margin-right', 8.8125rem);
    @include fluid('margin-left', -2rem);
    @include fluid(('border-top-width', 'border-bottom-width', 'border-left-width'), 1rem);
    @include fluid('border-right-width', 0.5rem);
    @include fluid('max-width', 14rem);

    position: relative;
    border-color: #EC6529;
    border-style: solid;

    border-right-color: transparent;

    .patch {
      @include fluid('margin-right', 0.5rem);
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1rem;
      transform: translate(0, -50%);
      background-color: #EC6529;
    }

    span {
      @include fluid('font-size', 0.75rem);
      @include fluid('line-height', 2rem);
      @include fluid('letter-spacing', 0.0625rem);
      @include fluid('top', -1rem);
      @include fluid('left', 1rem);
      @include fluid('height', 2rem);
      position: absolute;
      z-index: 1;
      font-weight: $font-weight-medium;
      color: #fff;
      bottom: 0;
    }
  }
  
  .tagline {
    @include fluid('font-size', 0.75rem);
    font-weight: $font-weight-light;
    line-height: 1.5;

    &.short {
      @include fluid('margin-bottom', 0.5rem);
    }
    
    &:not(.short) {
      @include fluid('width', 10.75rem);
    }
  }

  .product {
    overflow: hidden;
    display: none;

    &.show {
      display: block;
    }

    .prodname {
      @include fluid('font-size', 1.25rem);
      @include fluid('margin-top', 0.5rem);
      font-weight: $font-weight-medium;
      line-height: 1.2;
    }

    .price {
      font-weight: $font-weight-medium;
      @include fluid('font-size', 0.8rem);
      line-height: 1.2;
      margin: 0;
      @include fluid(('margin-top', 'margin-bottom'), 0.75rem);

      span {
        @include fluid('font-size', 0.5625rem);
        font-weight: $font-weight-light;
        line-height: 1.2;
      }

      .sku {
        @include fluid('margin-top', 0.5rem);
        font-weight: $font-weight-light;
      }
    }

    .prodimg {
      display: block;
      width: 100%;
      max-width: $btn-max-width;
      height: auto;

      &.print {
        display: none;
      }
    }

    .prodsummary {
      display: none;
    }

    .proddesc, .prodsummary {
      @include fluid('font-size', 0.75rem);
      font-weight: $font-weight-light;
      line-height: 1.5;

      p {
        @include fluid('margin-bottom', 0.5rem);
      }
      ul {
        @include fluid('padding-left', 1rem);
        margin: 0;
        @include fluid('margin-bottom', 0.5rem);
      }
    }

    .distr {
      @include fluid('font-size', 0.5625rem);
      @include fluid('margin-top', 1rem);
      font-style: italic;
      line-height: 1.4;
      text-align: center;
    }
  }

  .more {
    @include fluid('font-size', 0.875rem);
    @include fluid('margin-top', 0.875rem);
    font-weight: $font-weight-medium;
    color: #01A4AD;
    line-height: 1.4;
    text-align: center;
    cursor: pointer;
  }
}

@media print {
  .panel {
    position: relative;
    page-break-inside: avoid;

    .tagline {
      &:not(.short) { width: calc(100% - 7.125rem); }
    }

    .separator {
      display: none;
    }

    .product {
      position: relative;

      &:not(.pick) {
        display: block;

        .proddesc {
          display: none;
        }

        .prodsummary {
          display: block;
        }
      }

      .distr {
        display: none;
      }

      .prodimg:not(.print) {
        display: none;
      }

      .prodimg.print {
        display: block;
        float: right;
        width: 35%;
      }
    }

    .more {
      display: none;
    }
  }

  .recoswrapper {
    background-color: green;

    > .panel {
      width: 100%;
    }

    > .otherrecos {
      display: grid;
      grid-template-columns: repeat(auto-fit, 3.6725in); // 117px);
      grid-gap: 0.25in; // 22px;
  
      &:not(.pick) {
        display: inline-block;
        width: 2in;
        min-height: 5in;
      }
  
      .panel {
        display: block;
        width: 100%;
        max-width: $btn-max-width;
        height: auto;
        min-height: 4in;
  
        .prodimg {
          display: block;
          width: 0.5in;
          height: auto;      
        }
      }
    }
  }
}