@import "../../../style/variables";
@import "../../../style/mixins";

.interest {
  @include fluid('width', 8rem);
  float: right;

  .title {
    @include fluid('font-size', 0.75rem);
    @include fluid('margin-bottom', 0.5rem);
    @include fluid('letter-spacing', 0.0625rem);
    font-weight: $font-weight-medium;
    text-align: center;
  }

  .gauge {
    @include fluid('width', 7.125rem);
  }

  .level {
    @include fluid('font-size', 1.125rem);
    @include fluid('letter-spacing', 0.0625rem);
    @include fluid('margin-top', 0.5rem);
    font-weight: $font-weight-medium;
    line-height: 1;
    text-align: center;
  }
}

.modal-title {
  @include fluid('font-size', 0.75rem);
  @include fluid('letter-spacing', 0.0625rem);
  font-weight: $font-weight-medium;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
}

@media print {
  .interest {
    svg { display: none; }
  }
}